import { axiosInstance } from '../Axios';
import { storeData } from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const AgentReducer = createSlice({
	name: 'agent',
	initialState: {
		success: false,
		error: null,
		loading: false,
		agentsSignup: null,
		agentpassword: null,
		transHistory: null,
		walletagent: sessionStorage.getItem('walletagent')
			? JSON.parse(sessionStorage.getItem('walletagent'))
			: null,
		forgotPassword: null,
		agentInfo: sessionStorage.getItem('agentInfo')
			? JSON.parse(sessionStorage.getItem('agentInfo'))
			: null,
		agentedit: null,
		agentMarkup: sessionStorage.getItem('agentMarkup')
			? JSON.parse(sessionStorage.getItem('agentMarkup'))
			: null,
		markupedit: sessionStorage.getItem('markupedit')
			? JSON.parse(sessionStorage.getItem('markupedit'))
			: null,
	},
	reducers: {
		agentsignupRequest(state, action) {
			state.loading = true;
		},
		agentsignupSuccess(state, action) {
			state.loading = false;
			state.agentsSignup = action.payload;
			state.error = null;
		},
		agentsignupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agentloginRequest(state, action) {
			state.loading = true;
		},
		agentloginSuccess(state, action) {
			state.loading = false;
			state.agentInfo = action.payload;
			storeData('agentInfo', action.payload);
			state.error = null;
		},
		agentloginFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agentchangepasswordRequest(state, action) {
			state.loading = true;
		},
		agentchangepasswordSuccess(state, action) {
			state.loading = false;
			state.agentpassword = action.payload;
			state.error = null;
		},
		agentchangepasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agentbookinghistoryRequest(state, action) {
			state.loading = true;
		},
		agentbookinghistorySuccess(state, action) {
			state.loading = false;
			state.bookingHistory = action.payload;
			state.error = null;
		},
		agentbookinghistoryFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agenttransRequest(state, action) {
			state.loading = true;
		},
		agenttransSuccess(state, action) {
			state.loading = false;
			state.transHistory = action.payload;
			state.error = null;
		},
		agenttransFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		walletagentRequest(state, action) {
			state.loading = true;
		},
		walletagentSuccess(state, action) {
			state.loading = false;
			state.walletagent = action.payload;
			storeData('walletagent', action.payload);
			state.error = null;
		},
		walletagentFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupRequest(state, action) {
			state.loading = true;
		},
		markupSuccess(state, action) {
			state.loading = false;
			state.agentMarkup = action.payload;
			storeData('agentMarkup', action.payload);
			state.error = null;
		},
		markupFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		forgotPasswordRequest(state, action) {
			state.loading = true;
		},
		forgotPasswordSuccess(state, action) {
			state.loading = false;
			state.forgotPassword = action.payload;
			state.error = null;
		},
		forgotPasswordFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		agentedituserRequest(state, action) {
			state.loading = true;
		},
		agentedituserSuccess(state, action) {
			state.loading = false;
			state.agentedit = action.payload;
			state.error = null;
		},
		agentedituserSuccess(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
		markupEditRequest(state, action) {
			state.loading = true;
		},
		markupEditSuccess(state, action) {
			state.loading = false;
			state.markupedit = action.payload;
			state.error = null;
		},
		markupEditFail(state, action) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

const { actions } = AgentReducer;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		// 'Api-Key': 'NjA4ZGFkYWQ2MjY4MA==',
		'Api-Key': 'NjA4MzFiNDBkZmI1NQ==',
	},
};
export const {
	agentsignupRequest,
	agentsignupSuccess,
	agentsignupFail,
	agentloginRequest,
	agentloginSuccess,
	agentloginFail,
	agentchangepasswordRequest,
	agentchangepasswordSuccess,
	agentchangepasswordFail,
	agentbookinghistoryRequest,
	agentbookinghistorySuccess,
	agentbookinghistoryFail,
	agenttransRequest,
	agenttransSuccess,
	agenttransFail,
	walletagentRequest,
	walletagentSuccess,
	walletagentFail,
	markupRequest,
	markupSuccess,
	markupFail,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordFail,
	agentedituserRequest,
	agentedituserSuccess,
	agentedituserFail,
	markupEditRequest,
	markupEditSuccess,
	markupEditFail,
} = actions;

export const agentSignupDispatch =
	(bodyData, handleClose) => async (dispatch) => {
		try {
			dispatch(agentsignupRequest());
			handleClose();
			const { data } = await axiosInstance.post(
				'agent/register',
				bodyData,
				config
			);
			dispatch(agentsignupSuccess(data));
			if (data.status === 200) {
				alert('Registration Successfully Please Check Your Email');
			} else {
			}
		} catch (error) {
			dispatch(
				agentsignupFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const agentloginDispatch =
	(bodyData, handleClose, navigate, setOpen) => async (dispatch) => {
		try {
			dispatch(agentloginRequest());
			setOpen(false);
			const { data } = await axiosInstance.post(
				'agent/login',
				bodyData,
				config
			);
			dispatch(agentloginSuccess(data));
			handleClose();
			if (data.status === 200) {
				alert('Login Successfully');
				navigate('/flight');
				dispatch(
					walletBalanceAgentDispatch(
						data?.result?.result?.email,
						data?.result?.result?.id
					)
				);
				dispatch(agentMarkupDispatch(data?.result?.result?.id));
			} else {
				alert('Login Failed');
			}
		} catch (error) {
			dispatch(
				agentloginFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const agentchangePasswordDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(agentchangepasswordRequest());
		const { data } = await axiosInstance.post(
			'agent/resetpassword',
			bodyData,
			config
		);
		console.log(data);
		dispatch(agentchangepasswordSuccess(data));
	} catch (error) {
		dispatch(
			agentchangepasswordFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentbookinghistoryDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(agentbookinghistoryRequest());
		let formData = {
			userId: userId,
		};

		const { data } = await axiosInstance.post(
			'agent/bookinghistory',
			formData,
			config
		);
		dispatch(agentbookinghistorySuccess(data));
	} catch (error) {
		dispatch(
			agentbookinghistoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const agenttransactionhistoryDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(agenttransRequest());
		let formData = {
			agent_id: userId,
		};

		const { data } = await axiosInstance.post(
			'agent/agenttrans',
			formData,
			config
		);
		dispatch(agenttransSuccess(data));
	} catch (error) {
		dispatch(
			agenttransFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const walletBalanceAgentDispatch =
	(userEmail, userId) => async (dispatch) => {
		try {
			dispatch(walletagentRequest());
			let formData = {
				userEmail: userEmail,
				userId: userId,
			};

			const { data } = await axiosInstance.post(
				'agent/walletbalance',
				formData,
				config
			);
			dispatch(walletagentSuccess(data));
		} catch (error) {
			dispatch(
				walletagentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const agentMarkupDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(markupRequest());
		let formData = {
			userId: userId,
		};

		const { data } = await axiosInstance.post(
			'agent/agentmarkup',
			formData,
			config
		);
		dispatch(markupSuccess(data));
	} catch (error) {
		dispatch(
			markupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const forgotPasswordDispatch = (formData) => async (dispatch) => {
	try {
		dispatch(forgotPasswordRequest());

		const { data } = await axiosInstance.post(
			'agent/forgotpassword',
			formData,
			config
		);
		dispatch(forgotPasswordSuccess(data));
		if (data.status === 200) {
		} else {
		}
	} catch (error) {
		dispatch(
			forgotPasswordFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentedituserDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(agentedituserRequest());

		const { data } = await axiosInstance.post(
			'agent/edituser',
			bodyData,
			config
		);
		dispatch(agentedituserSuccess(data));
		if (data.status === 200) {
			alert('Update SuccessFully');
		} else {
		}
	} catch (error) {
		dispatch(
			agentedituserFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const agentEditMarkupDispatch = (bodyData, id) => async (dispatch) => {
	try {
		dispatch(markupEditRequest());

		const { data } = await axiosInstance.post(
			'agent/agenteditmarkup',
			bodyData,
			config
		);
		dispatch(markupEditSuccess(data));
		dispatch(agentMarkupDispatch(id));
		if (data.status === 200) {
			alert('Update Successfully');
		}
	} catch (error) {
		dispatch(
			markupEditFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export default AgentReducer;
